<template>
  <CModal
    title="Change interval"
    color="primary"
    :show.sync="order"
    @update:show="close"
  >
    <template #footer>
      <h5 class="modal-title">
        <CButton color="primary" square class="float-right" @click="update">
          {{ $t("buttons.save") }}
        </CButton>
      </h5>
    </template>

    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
      {{ errors.message }}
      <ul>
        <li v-for="(error, field) in errors.errors" :key="field">
          {{ error[0] }}
        </li>
      </ul>
    </CAlert>

    <CRow>
      <CCol sm="12">
        <CTabs
          variant="pills"
          :active-tab="activeTab"
          @update:activeTab="activeTab = $event"
        >
          <CTab
            :title="label"
            v-for="(label, key) in { today: 'Today', tomorrow: 'Tomorrow' }"
            :key="key"
          >
            <CInputRadioGroup
              :options="getFormattedIntervals(intervals[key] || [])"
              @update:checked="setInterval"
              class="mt-3"
            />
          </CTab>
        </CTabs>
      </CCol>
    </CRow>
  </CModal>
</template>

<script>
import { formatInterval } from "@/helpers";

export default {
  props: ["order"],

  data() {
    return {
      isLoading: true,
      errors: [],
      activeTab: 0,
      intervals: {},
      activeIndex: null,
      form: {
        deliver_from: null,
        deliver_to: null,
        period: null
      }
    };
  },

  watch: {
    order: function(id) {
      if (id !== null) {
        this.getIntervals();
      }
    }
  },

  computed: {
    activeKey() {
      return this.activeTab === 0 ? "today" : "tomorrow";
    }
  },

  methods: {
    getIntervals() {
      this.$http
        .get(`/api/manager/orders/${this.order}/delivery-intervals`)
        .then(response => {
          this.intervals = response.data.data;
          this.isLoading = false;
        });
    },
    getFormattedIntervals(intervals) {
      return intervals.map((interval, index) => {
        return {
          label: formatInterval(interval.from, interval.to),
          value: index
        };
      });
    },
    setInterval(index) {
      const interval = this.intervals[this.activeKey][index];
      this.activeIndex = index;
      this.form.deliver_from = interval.from;
      this.form.deliver_to = interval.to;
      this.form.period = interval.period;
    },
    update() {
      const interval = this.intervals[this.activeKey][this.activeIndex];
      if (
        !interval.available &&
        !confirm("Selected interval is not available. Proceed?")
      ) {
        return;
      }

      this.isLoading = true;
      this.errors = [];
      this.$http
        .put(`/api/manager/orders/${this.order}`, this.form)
        .then(() => {
          this.close();
          this.$emit("updated");
        })
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit("closed");
    }
  }
};
</script>
