import { render, staticRenderFns } from "./OrderMobileForm.vue?vue&type=template&id=48526d87&scoped=true"
import script from "./OrderMobileForm.vue?vue&type=script&lang=js"
export * from "./OrderMobileForm.vue?vue&type=script&lang=js"
import style0 from "./OrderMobileForm.vue?vue&type=style&index=0&id=48526d87&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48526d87",
  null
  
)

export default component.exports