<template>
  <CModal
    title="Replace the dish"
    color="primary"
    :show.sync="subOrder"
    @update:show="close"
  >
    <template #footer>
      <h5 class="modal-title">
        <CButton color="primary" square class="float-right" @click="replace">
          {{ $t("buttons.save") }}
        </CButton>
      </h5>
    </template>

    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
      {{ errors.message }}
      <ul>
        <li v-for="(error, field) in errors.errors" :key="field">
          {{ error[0] }}
        </li>
      </ul>
    </CAlert>

    <CRow>
      <CCol sm="12">
        <label>{{ $t("admin.restaurants.sections.dishes.dish") }}</label>
        <multiselect
          v-model="dish"
          :options="dishes"
          :close-on-select="true"
          :preserve-search="true"
          tag-placeholder=""
          label="name"
          track-by="id"
          @input="form.dish = $event.id"
        />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.toppings.title") }}</label>
        <Checkbox
          :label="topping.name"
          :item="topping"
          :active-items.sync="form.toppings"
          v-for="topping in dish.toppings"
          :key="topping.id"
        />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.toppings_addit.title") }}</label>
        <Checkbox
          :label="topping_addit.name"
          :item="topping_addit"
          :active-items.sync="form.toppings_addit"
          v-for="topping_addit in dish.toppings_addit"
          :key="topping_addit.id"
        />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.modifications.title") }}</label>
        <CInputRadioGroup
          :options="formatAdditions(dish.modifications || [])"
          @update:checked="form.modification = $event"
        />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.accompaniments.title") }}</label>
        <CInputRadioGroup
          :options="formatAdditions(dish.accompaniments || [])"
          @update:checked="form.accompaniment = $event"
        />
      </CCol>

      <CCol sm="12">
        <CInput
          :label="$t('admin.orders.quantity')"
          type="number"
          v-model="form.quantity"
        />
      </CCol>

      <CCol sm="12">
        <CTextarea :label="$t('admin.orders.comment')" v-model="form.comment" />
      </CCol>
    </CRow>
  </CModal>
</template>

<script>
import Multiselect from "vue-multiselect";
import Checkbox from "@/components/admin/Checkbox";

export default {
  props: ["subOrder", "restaurant"],

  components: {
    Multiselect,
    Checkbox
  },

  data() {
    return {
      isLoading: true,
      errors: [],
      dishes: [],
      dish: {},
      form: {
        toppings: [],
        quantity: 1,
        comment: "",
        period: 0
      }
    };
  },

  watch: {
    subOrder: function(id) {
      if (id !== null) {
        this.getDishes();
      }
    }
  },

  methods: {
    getDishes() {
      this.$http
        .get(`/api/admin/restaurants/${this.restaurant}/dishes`)
        .then(response => {
          this.dishes = response.data.data;
          this.isLoading = false;
        });
    },
    replace() {
      this.isLoading = true;
      this.errors = [];
      this.form.period = this.dish.primary_period;
      this.$http
        .put(`/api/admin/sub-order-items/${this.subOrder}`, this.form)
        .then(() => {
          this.close();
          this.$emit("replaced");
        })
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    formatAdditions(additions) {
      return additions.map(addition => {
        return { label: addition.name, value: addition.id.toString() };
      });
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit("closed");
    }
  }
};
</script>
