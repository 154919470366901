<template>
  <CModal
    title="Add dish"
    color="primary"
    :show.sync="show"
    @update:show="close"
  >
    <template #footer>
      <h5 class="modal-title">
        <CButton v-if="!isLoading" color="primary" square class="float-right" @click="store">
          {{ $t("buttons.save") }}
        </CButton>
      </h5>
    </template>

    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
      {{ errors.message }}
      <ul>
        <li v-for="(error, field) in errors.errors" :key="field">
          {{ error[0] }}
        </li>
      </ul>
    </CAlert>

    <CRow>
      <CCol sm="12">
        <label>{{ $t("admin.restaurant") }}</label>
        <CSelect :options="restaurants" :value.sync="restaurant" />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.sections.dishes.dish") }}</label>
        <multiselect
          v-model="dish"
          :options="dishes"
          :close-on-select="true"
          :preserve-search="true"
          tag-placeholder=""
          label="name"
          track-by="id"
          @input="form.dish = $event.id"
        />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.toppings.title") }}</label>
        <Checkbox
          :label="topping.name"
          :item="topping"
          :active-items.sync="form.toppings"
          v-for="topping in dish.toppings"
          :key="topping.id"
        />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.toppings_addit.title") }}</label>
        <Checkbox
          :label="topping_addit.name"
          :item="topping_addit"
          :active-items.sync="form.toppings_addit"
          v-for="topping_addit in dish.toppings_addit"
          :key="topping_addit.id"
        />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.modifications.title") }}</label>
        <CInputRadioGroup
          :options="formatAdditions(dish.modifications || [])"
          @update:checked="form.modification = $event"
        />
      </CCol>

      <CCol sm="12">
        <label>{{ $t("admin.restaurants.accompaniments.title") }}</label>
        <CInputRadioGroup
          :options="formatAdditions(dish.accompaniments || [])"
          @update:checked="form.accompaniment = $event"
        />
      </CCol>

      <CCol sm="12">
        <CInput
          :label="$t('admin.orders.quantity')"
          type="number"
          v-model="form.quantity"
        />
      </CCol>

      <CCol sm="12">
        <CTextarea :label="$t('admin.orders.comment')" v-model="form.comment" />
      </CCol>
    </CRow>
  </CModal>
</template>

<script>
import Multiselect from "vue-multiselect";
import Checkbox from "@/components/admin/Checkbox";

export default {
  props: ["subOrders", "order", "show"],

  components: {
    Multiselect,
    Checkbox
  },

  data() {
    return {
      isLoading: true,
      errors: [],
      restaurants: [],
      dishes: [],
      restaurant: null,
      dish: {},
      form: {
        toppings: [],
        toppings_addit: [],
        quantity: 1,
        period: 0,
        comment: ""
      }
    };
  },

  watch: {
    show: function(show) {
      if (show) {
        if (this.subOrders) {
          this.restaurants = this.subOrders.map(subOrder => ({
            label: subOrder.restaurant,
            value: subOrder.restaurant_id
          }));
          if (this.restaurants.length > 0) {
            this.restaurant = this.restaurants[0].value;
          }
        } else {
          this.$http.get("/api/admin/restaurants").then(response => {
            this.restaurants = response.data.data.map(restaurant => ({
              label: restaurant.name,
              value: restaurant.id,
              cooking_time: restaurant.cooking_time,
              address: restaurant.address
            }));
            if (this.restaurants.length > 0) {
              this.restaurant = this.restaurants[0].value;
            }
            this.isLoading = false;
          });
        }
      }
    },
    restaurant: function(id) {
      if (id !== null) {
        this.getDishes();
      }
    }
  },

  methods: {
    getDishes() {
      this.isLoading = true;
      this.$http
        .get(`/api/admin/restaurants/${this.restaurant}/dishes`)
        .then(response => {
          this.dishes = response.data.data;
          this.isLoading = false;
        });
    },
    store() {
      this.isLoading = true;
      this.errors = [];
      if (this.subOrders) {
        const subOrder = this.subOrders.find(
          subOrder => subOrder.restaurant_id === this.restaurant
        ).id;
        this.form.period = this.dish.primary_period;
        this.$http
          .post(`/api/admin/sub-orders/${subOrder}/sub-order-items`, this.form)
          .then(() => {
            this.close();
            this.$emit("stored");
          })
          .catch(error => (this.errors = error.response.data))
          .finally(() => (this.isLoading = false));
      } else {
        const rest = this.restaurants.find(
          rest => rest.value === this.restaurant
        );
        this.form.period = this.dish.primary_period;
        this.$http
          .post("/api/cart-items", this.form)
          .then(response => {
            let addit = [];
            this.form.id = response.data.id;
            const _form = this.form;
            addit = addit.concat(
              this.dish.accompaniments
                .filter(function(elem) {
                  if (_form.accompaniment == elem.id) return true;
                })
                .map(function(elem) {
                  return {
                    id: elem.id,
                    name: elem.name,
                    type: "accompaniments",
                    price_buy: elem.price_buy,
                    price_sell: elem.price_sell
                  };
                })
            );
            addit = addit.concat(
              this.dish.modifications
                .filter(function(elem) {
                  if (_form.modification == elem.id) return true;
                })
                .map(function(elem) {
                  return {
                    id: elem.id,
                    name: elem.name,
                    type: "modifications",
                    price_buy: elem.price_buy,
                    price_sell: elem.price_sell
                  };
                })
            );
            addit = addit.concat(
              this.dish.toppings
                .filter(function(elem) {
                  if (_form.toppings.includes(elem.id)) return true;
                })
                .map(function(elem) {
                  return {
                    id: elem.id,
                    name: elem.name,
                    type: "toppings",
                    price_buy: elem.price_buy,
                    price_sell: elem.price_sell
                  };
                })
            );
            addit = addit.concat(
              this.dish.toppings_addit
                .filter(function(elem) {
                  if (_form.toppings.includes(elem.id)) return true;
                })
                .map(function(elem) {
                  return {
                    id: elem.id,
                    name: elem.name,
                    type: "toppings_addit",
                    price_buy: elem.price_buy,
                    price_sell: elem.price_sell
                  };
                })
            );
            this.$emit(
              "stored",
              this.form,
              rest,
              this.restaurant,
              addit,
              this.dish
            );
            this.close();
          })
          .catch(error => (this.errors = error.response.data))
          .finally(() => (this.isLoading = false));
      }
    },
    formatAdditions(additions) {
      return additions.map(addition => {
        return { label: addition.name, value: addition.id.toString() };
      });
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit("closed");
    }
  }
};
</script>
