<template>
  <CInputCheckbox
    @update:checked="checked"
    :label="label"
    :value="item.id"
    :checked="activeItems.filter(id => id === item.id).length > 0"
  />
</template>

<script>
import { getActiveCheckboxItems } from "@/helpers";

export default {
  props: ["label", "activeItems", "item"],

  methods: {
    checked(checked) {
      this.$emit(
        "update:activeItems",
        getActiveCheckboxItems(this.activeItems, this.item, checked)
      );
    }
  }
};
</script>
