<template>
  <CModal
    title="Edit address"
    color="primary"
    :show.sync="show"
    @update:show="close"
  >
    <template #footer>
      <h5 class="modal-title">
        <CButton
          v-if="!isLoading"
          color="primary"
          square
          class="float-right"
          @click="save"
        >
          {{ $t("buttons.save") }}
        </CButton>
      </h5>
    </template>

    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
      {{ errors.message }}
      <ul>
        <li v-for="(error, field) in errors.errors" :key="field">
          {{ error[0] }}
        </li>
      </ul>
    </CAlert>

    <CRow>
      <CCol sm="12">
        <label>{{ $t("delivery_address") }}</label>
        <CSelect :options="delivery_addresses" :value.sync="delivery_address" />
      </CCol>
    </CRow>
  </CModal>
</template>

<script>
export default {
  props: ["order", "user", "show"],

  data() {
    return {
      isLoading: true,
      errors: [],
      delivery_addresses: [],
      delivery_address: null
    };
  },

  watch: {
    show: function(show) {
      if (show) {
        this.$http.get(`/api/admin/users/${this.user}`).then(response => {
          this.delivery_addresses = response.data.data.addresses.map(
            address => ({
              label: address.short,
              value: address.id
            })
          );
          if (this.delivery_addresses.length > 0) {
            this.delivery_address = this.delivery_addresses[0].value;
          }
          this.isLoading = false;
        });
      }
    }
  },

  methods: {
    save() {
      this.isLoading = true;
      this.errors = [];
      this.$http
        .patch(`/api/admin/orders/${this.order}`, {
          delivery_address: this.delivery_address
        })
        .then(() => {
          this.close();
          this.$emit("updated");
        })
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit("closed");
    }
  }
};
</script>
