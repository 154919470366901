export const makeFormData = (data, isEditMode) => {
  const formData = new FormData();

  if (isEditMode) formData.append("_method", "PUT");

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (Array.isArray(data[key])) {
        let index = 0;
        data[key].forEach(item => {
          if (item === Object(item)) {
            for (const [obj_key, obj_val] of Object.entries(item)) {
              formData.append(`${key}[${index}][${obj_key}]`, obj_val);
            }
            index++;
          } else {
            formData.append(`${key}[]`, item);
          }
        });
      } else if (
        data[key] === Object(data[key]) &&
        !(data[key] instanceof File)
      ) {
        for (const [obj_key, obj_val] of Object.entries(data[key])) {
          formData.append(`${key}[${obj_key}]`, obj_val);
        }
      } else {
        formData.append(key, data[key]);
      }
    }
  }

  return formData;
};

export const getActiveCheckboxItems = (items, item, isChecked) => {
  let ids = items;

  if (isChecked) {
    ids.push(item.id);
  } else {
    ids = ids.filter(id => id !== item.id);
  }

  return ids;
};

export const formatInterval = (from, to) => {
  function split(date) {
    return new Date(date)
      .toLocaleTimeString("en-GB", { timeZone: "Europe/Paris" })
      .split(":");
  }

  let [from_hour, from_minute] = split(from);
  let [to_hour, to_minute] = split(to);

  return `${from_hour}:${from_minute} - ${to_hour}:${to_minute}`;
};

export const mapBounds = google => {
  const coordinates = [
    [43.7154107, 7.3867607],
    [43.7191327, 7.4182995],
    [43.7562480, 7.4563694],
    [43.7606882, 7.4528825],
    [43.7586425, 7.4459195],
    [43.7559845, 7.4448681],
    [43.7555195, 7.4383664],
    [43.7273205, 7.3918707],
    [43.7154107, 7.3867607]
  ];
  const bounds = new google.maps.LatLngBounds();

  coordinates.forEach(lat_lng => {
    bounds.extend(new google.maps.LatLng(lat_lng[0], lat_lng[1]));
  });

  return bounds;
};

export const getCourierMarker = colour => {
  return {
    path:
      "M11,0A8,8,0,0,0,3,8c0,5.45,7.13,13.45,7.44,13.79a.71.71,0,0,0,1.06,0C11.84,21.42,19,13.42,19,8A8,8,0,0,0,11,0Zm0,12a4,4,0,1,1,4-4A4,4,0,0,1,11,12Z",
    fillColor: colour,
    fillOpacity: 1.0
  };
};
